import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import NotFounds from '../views/Page/NotFoundsView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/adventure',
    name: 'Adventure',
    component: () => import(/* webpackChunkName: "adventure" */ '../views/Categorys/AdventureView.vue'),
  },
  {
    path: '/arcade',
    name: 'Arcade',
    component: () => import(/* webpackChunkName: "arcade" */ '../views/Categorys/ArcadeView.vue'),
  },
  {
    path: '/puzzles',
    name: 'Puzzles',
    component: () => import(/* webpackChunkName: "puzzles" */ '../views/Categorys/PuzzlesView.vue'),
  },
  {
    path: '/sports',
    name: 'Sports',
    component: () => import(/* webpackChunkName: "sports" */ '../views/Categorys/SportsView.vue'),
  },
  {
    path: '/strategy',
    name: 'Strategy',
    component: () => import(/* webpackChunkName: "strategy" */ '../views/Categorys/StrategyView.vue'),
  },
  {
    path: '/gamesDetails/:gid',
    name: 'GamesDetails',
    component: () => import(/* webpackChunkName: "gamesDetails" */ '../views/Page/GamesDetailsView.vue'),
    props: true,
  },
  {
    path: '/playGames/:gid',
    name: 'PlayGames',
    component: () => import(/* webpackChunkName: "playGames" */ '../views/Page/PlayGamesView.vue'),
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/LoginView.vue'),
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/User/UnsubscribesView.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFounds', component: NotFounds },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
