<template>
   <app-header/>
   <app-not-founds/>
   <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppNotFounds from '../../components/Pages/AppNotFounds.vue'
export default {
  components: { AppHeader, AppFooter, AppNotFounds },
   name: 'NotFoundsView'
}
</script>

<style>

</style>